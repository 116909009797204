<template>
  <div class="error-page">
    <div class="error-code"></div>
  </div>
</template>

<script>
export default {
  methods: {}
};
</script>

<style scoped>
.error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.error-code {
  width: 100%;
  height: 100%;
  background-size: 100% !important;
  background: url("../../assets/images/home/404.png") no-repeat center center;
}
</style>
